
  .crop-container {
    position: relative;
    margin: auto;
    height: 360px; 
    width: 360px;
    border: 1px solid rgb(227, 228, 232);
    border-radius: 2px;
   
  }
  .crop-container img {
    /* margin: auto; */
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .controls {
    box-sizing: border-box;
    /* position: absolute;
    bottom: 0;
    left: 0%; */
    width: 100%;
    padding: 0 16px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .slider {
    padding: 22px 0px;
  }
  
  .carousel-container {
    margin-top: 2px;
    width: 100%;
    /* height: 100%; */
    position: relative;
    background-color: #f7f9f3be;
    
    /* box-shadow: 0px 0px 5px rgb(202, 217, 254); */
  }
  .crop-container .reactEasyCrop_CropArea{
    border: none !important;
    box-shadow: none !important;
  }

  .custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.2);
    border: none;
    padding: 6px;
    cursor: pointer;
    color: white;
    z-index: 2;
    border-radius: 6px;
    outline: none;
  }
  .custom-prev-arrow {
    left: 5px;
  }
 
  .custom-next-arrow {
    right: 5px;
  }