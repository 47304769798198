body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box !important;
}

/* for custom tooltips - */
[data-mui-internal-clone-element="true"] {
  /* width: 100%; */
  display: flex;
}
