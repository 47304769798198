.sliding-component {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    animation: slide-up 0.5s ease-out;
    z-index: 1;
    max-height: calc(100vh - 60px);
    overflow: auto;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }