.post_header{
    display: flex;
    width: 100%;
    /* justify-content: space-between; */
    align-items: center;
    /* padding: 0 16px; */


}

.video_container
{
    width: 100%;
    position: relative;
    cursor: pointer;
}


.image_container{
    width: 100%;
    position: relative;
    cursor: pointer;
    height: auto;
    max-height: 32vh;
    overflow: hidden;
    /* border: 2px solid; */
}


.video_container video
{
    width: 100%;
    position: relative;
    object-fit: cover;
}


.image_container img{
    width: 100%;
    position: relative;
    object-fit: contain;
    /* height: 400px; */
    height: 17.32rem;
}


.speaker_icon_button{
    /* position: absolute;
    bottom: 12%;
    right: 5%; */
    border-radius: 1rem;
    padding: auto;
    background: black;
    color: white;
    cursor: pointer;
    border: none;
    outline: none;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image_counter{
    position: absolute;
    top: 2%;
    right: 3%;
    border-radius: .7rem;
    padding: auto;
    background: black;
    color: white;
    border: none;
    outline: none;
    width: 2rem;
    height: 1.35rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px !important;
}


.slash_divider{
    height: 9px;
    background: black;
    transform: rotate(18deg);
    border: 0 1px 0 1px;
    border-left: 1.5px solid;
    margin: 0 1.5px 0 1.5px;
    border-radius: 1rem;
}
.play_pause_button{
    position: absolute !important;
    left: calc(50% - 2rem);
    top: calc(50% - 1.3rem);
    transform: translate(50%, 50%, 0);
    transform: translate(50%);
    transform-origin: center;
    cursor: pointer;
    border: none;
    outline: none;
    transition: opacity 0.5s ease-in-out;
    background: white;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}