.video-cropper-container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  
  .crop-container {
    /* position: relative; */
    width: 360px; /* Adjust the width and height as needed */
    height: 360px;
    display: flex;
    flex-direction: column;
    background-color: black; /* Optional: background to emphasize the video area */
  }
  .crop-container video {
    /* margin-top: auto !important; */
    /* width: 100%;
    height: 100%; */
  }
  
  .carousel-container {
    width:380px; /* Adjust carousel to take full width */
    height: 380px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  
  .zoom-controls {
    margin-top: 20px;
  }
 